/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        var offsetHeight = $('.banner').outerHeight();
        if ($(".section-background-fullscreen")[0]) {
          offsetHeight = '';
        }

        $('body').css('padding-top', offsetHeight);
        $('.menu-actions section > .section-content').css('height', $('.banner > .banner-content > .section-content').outerHeight());

        $(window).resize(function () {
          var offsetHeight = $('.banner').outerHeight();
          if ($(".section-background-fullscreen")[0]) {
            offsetHeight = '';
          }
          $('body').css('padding-top', offsetHeight);
          $('.menu-actions section > .section-content').css('height', $('.banner > .banner-content > .section-content').outerHeight());
        });

        function openSubmenu(element) {
          element.addClass('active');
          element.next('.submenu').addClass('active');
          $('.close-sub-menu').addClass('active');
        }

        function closeSubmenu() {
          $('.submenu-toggle.active,.submenu.active,.close-sub-menu.active').removeClass('active');
        }

        $('.menu-toggle').click(function () {
          closeSubmenu();
          if (!$(this).hasClass('close-sub-menu')) {
            $('.main-menu').toggleClass('active');
          }
        });

        $('.submenu-toggle').click(function (e) {
          e.preventDefault();

          if (!$(this).hasClass('active')) {
            closeSubmenu();
            openSubmenu($(this));
          } else {
            closeSubmenu();
          }

        });

        $('.main-menu-overlay').click(function (e) {
          closeSubmenu();
          $('.main-menu').removeClass('active');
        });


        function smoothScrollHash(hash) {
          var offsetHeight = $('header.banner').outerHeight();
          if ($(".section-background-fullscreen")[0]) {
            offsetHeight = '';
          }

          if ($(hash).length) {
            // close submenu and main menu
            closeSubmenu();
            $('.main-menu').removeClass('active');

            var scrolloffset = $(hash).offset().top - offsetHeight;
            console.log(scrolloffset);
            // if ($('.banner .dropdown-menu.show').length && ($('.banner .dropdown-menu.show').css('position') === 'relative')) {
            //   scrolloffset -= $('.dropdown-menu.show').outerHeight();
            // }
            $('html, body').animate({
              scrollTop: scrolloffset
            }, 900, 'swing');
          }
        }

        $('a[href*="#"]:not(.team-popup-toggle)').on('click', function () {
          var hash = $(this).attr('href');
          hash = hash.split('#');
          if (hash) {
            smoothScrollHash('#' + hash[1]);
          }
        });

        if (window.location.hash) {
          smoothScrollHash(window.location.hash);
        }

        $('.slick-slider').each(function (index) {
          $(this).slick({
            arrows: true,
            prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-primary btn-icoon"><i class="fas fa-chevron-left"></i></button></div>',
            nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-primary btn-icoon"><i class="fas fa-chevron-right"></i></button></div>',
            fade: true,
            waitForAnimate: false,
          });
        });

        $('.slick-logos').each(function (index) {
          $(this).slick({
            arrows: false,
            dots: true,
            mobileFirst: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 6
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              }
            ]
          });
        });

        $('.slider-hero').slick({
          prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-primary btn-icoon"><i class="fas fa-chevron-left"></i></button></div>',
          nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-primary btn-icoon"><i class="fas fa-chevron-right"></i></button></div>',
          autoplay: true,
          autoplaySpeed: 10000,
          arrows: false,
          dots: true,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                dots: false
              }
            }
          ]
        });



        $('.view-gallery').click(function (e) {
          e.preventDefault();
          $(this).closest('.section-content').prev('.section-content').find('.my-gallery.row figure:nth-child(7)').trigger('click');
        });

        $('a.magnific').magnificPopup({
          ajax: {
            settings: null, // Ajax settings object that will extend default one - http://api.jquery.com/jQuery.ajax/#jQuery-ajax-settings
            // For example:
            // settings: {cache:false, async:false}

            cursor: 'mfp-ajax-cur', // CSS class that will be added to body during the loading (adds "progress" cursor)
            tError: '<a href="%url%">The content</a> could not be loaded.', //  Error message, can contain %curr% and %total% tags if gallery is enabled
            modal: true
          },
          type: 'ajax',
          callbacks: {
            parseAjax: function (mfpResponse) {
              // mfpResponse.data is a "data" object from ajax "success" callback
              // for simple HTML file, it will be just String
              // You may modify it to change contents of the popup
              // For example, to show just #some-element:
              // mfpResponse.data = $(mfpResponse.data).find('#some-element');

              // mfpResponse.data must be a String or a DOM (jQuery) element

              mfpResponse.data = $(mfpResponse.data).find('#content-popup');

              //console.log('Ajax content loaded:', mfpResponse);
            }
          }
        });

        $('.team-popup-toggle').magnificPopup({
          type: 'inline',
          midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });

        var initPhotoSwipeFromDOM = function (gallerySelector) {

          // parse slide data (url, title, size ...) from DOM elements 
          // (children of gallerySelector)
          var parseThumbnailElements = function (el) {
            var thumbElements = el.childNodes,
              numNodes = thumbElements.length,
              items = [],
              figureEl,
              linkEl,
              size,
              item;

            for (var i = 0; i < numNodes; i++) {

              figureEl = thumbElements[i]; // <figure> element

              // include only element nodes 
              if (figureEl.nodeType !== 1) {
                continue;
              }

              linkEl = figureEl.children[0]; // <a> element

              size = linkEl.getAttribute('data-size').split('x');

              // create slide object
              item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
              };



              if (figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
              }

              if (linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
              }

              item.el = figureEl; // save link to element for getThumbBoundsFn
              items.push(item);
            }

            return items;
          };

          // find nearest parent element
          var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
          };

          // triggers when user clicks on thumbnail
          var onThumbnailsClick = function (e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            var eTarget = e.target || e.srcElement;

            // find root element of slide
            var clickedListItem = closest(eTarget, function (el) {
              return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
            });

            if (!clickedListItem) {
              return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.parentNode,
              childNodes = clickedListItem.parentNode.childNodes,
              numChildNodes = childNodes.length,
              nodeIndex = 0,
              index;

            for (var i = 0; i < numChildNodes; i++) {
              if (childNodes[i].nodeType !== 1) {
                continue;
              }

              if (childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
              }
              nodeIndex++;
            }



            if (index >= 0) {
              // open PhotoSwipe if valid index found
              openPhotoSwipe(index, clickedGallery);
            }
            return false;
          };

          // parse picture index and gallery index from URL (#&pid=1&gid=2)
          var photoswipeParseHash = function () {
            var hash = window.location.hash.substring(1),
              params = {};

            if (hash.length < 5) {
              return params;
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
              if (!vars[i]) {
                continue;
              }
              var pair = vars[i].split('=');
              if (pair.length < 2) {
                continue;
              }
              params[pair[0]] = pair[1];
            }

            if (params.gid) {
              params.gid = parseInt(params.gid, 10);
            }

            return params;
          };

          var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
              gallery,
              options,
              items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {

              // define gallery index (for URL)
              galleryUID: galleryElement.getAttribute('data-pswp-uid'),

              getThumbBoundsFn: function (index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                  pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                  rect = thumbnail.getBoundingClientRect();

                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
              }

            };

            // PhotoSwipe opened from URL
            if (fromURL) {
              if (options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                  if (items[j].pid === index) {
                    options.index = j;
                    break;
                  }
                }
              } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
              }
            } else {
              options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
              return;
            }

            if (disableAnimation) {
              options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
          };

          // loop through all gallery elements and bind events
          var galleryElements = document.querySelectorAll(gallerySelector);

          for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
          }

          // Parse URL and open gallery if it contains #&pid=3&gid=1
          var hashData = photoswipeParseHash();
          if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
          }
        };

        // execute above function
        initPhotoSwipeFromDOM('.my-gallery');

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Hide Header on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('.banner').outerHeight();

        $(window).scroll(function (event) {
          didScroll = true;
        });

        function handleScroll() {
          var st = $(window).scrollTop();

          // Ensure they scroll more than delta
          if (Math.abs(lastScrollTop - st) <= delta) {
            return;
          }

          // If they scrolled down and are past the navbar, add class .nav-up.
          if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $('.banner').removeClass('nav-down').addClass('nav-up');
            $('.banner-top').addClass('collapsed');
          } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
              $('.banner').removeClass('nav-up').addClass('nav-down');
              $('.banner-top').removeClass('collapsed');
            }
          }
          lastScrollTop = st;
        }

        setInterval(function () {
          if (didScroll) {
            handleScroll();
            didScroll = false;
          }
        }, 250);

        if (typeof InstagramToken !== 'undefined') {
          var amount = $("#instagram-feed").data("amount");
          var instaFeed = new Instafeed({
            get: 'user',
            accessToken: InstagramToken,
            limit: amount,
            target: 'instagram-feed',
            template: '<div class="instafeed-item col alt-col slick-slide-item"><a href="{{link}}" target="_blank" class="instafeed-item-image" style="background-image:url({{image}});"><i class="fab fa-instagram"></i></a></div>',
            after: function (image) {

              $('.slider-instafeed').slick({
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 6000,
                //touchThreshold: 100,
                //edgeFriction: 0,
                //swipeToSlide: true,
                cssEase: 'linear',
                responsive: [
                  {
                    breakpoint: 767,
                    settings: {
                      dots: true,
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      autoplay: false,
                      autoplaySpeed: 6000,
                      speed: 400,
                    }
                  }
                ]
              });
            }
          });
          instaFeed.run();
        }
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $(".lightbox-image").click(function (e) {
    e.stopPropagation();
    $(this).removeClass("d-flex");
  });

  $(".lightbox").click(function (e) {
    e.stopPropagation();
    $(this).find(".lightbox-image").addClass("d-flex");
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
